import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import {
    LG_APP_INFO,
    LG_NAVIGATION,
    LG_MATOMO_CONFIGURATION,
    LgApplicationModule,
    LgMatTrackingService,
} from "@logex/framework/lg-application";
import { LG_APPLICATION_MULTI_EVENT_TRACERS } from "@logex/framework/core";

import {
    CodmanBackendInterceptor,
    SharedConfigService,
    SharedUtilLogexFrameworkRootModule,
} from "@codman/shared/util-logex-framework-setup";
import { SharedUtilAuthorizationModule } from "@codman/shared/util-authorization";
import { SharedNavigationService } from "@codman/shared/data-access-shared-api";
import { MatomoConfiguration } from "@codman/shared/util-tracking";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        SharedUtilLogexFrameworkRootModule,
        SharedUtilAuthorizationModule.forRoot(),
        LgApplicationModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (startup: SharedConfigService) => startup.initialize(),
            deps: [SharedConfigService],
            multi: true,
        },
        {
            provide: LG_APP_INFO,
            useFactory: (configService: SharedConfigService) => {
                const config = configService.getConfiguration();
                const buildNumber = config?.version;
                return {
                    environment: config?.environment,
                    fullAppName: "Codman Portal",
                    productId: "portal",
                    toolInstanceName: "",
                    versionNumber: `2.${buildNumber ?? "000"}`,
                    overrideCurrencyPrefix: "",
                    overrideCurrencySuffix: "",

                    doNotDoGaTracking: () => false,
                    isProduction: () => config?.environment === "prod",
                };
            },
            deps: [SharedConfigService],
        },
        {
            provide: LG_NAVIGATION,
            useFactory: (navigationService: SharedNavigationService) =>
                navigationService.registryNavigation$,
            deps: [SharedNavigationService],
        },
        {
            provide: LG_MATOMO_CONFIGURATION,
            useExisting: MatomoConfiguration,
        },
        {
            provide: LG_APPLICATION_MULTI_EVENT_TRACERS,
            useExisting: LgMatTrackingService,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CodmanBackendInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
