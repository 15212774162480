import { inject } from "@angular/core";
import { CanActivateFn, ActivatedRouteSnapshot, Router } from "@angular/router";
import { combineLatest, defaultIfEmpty, filter, map, startWith, switchMap } from "rxjs";

import { CodmanUserAuthorizationService } from "@codman/shared/data-access-authorization";
import { DataAccessAuthorizationApiService } from "@codman/shared/data-access-authorization-api";

const NO_ORGANIZATION = -999;

export const portalGuard: CanActivateFn = (routeSnapshot: ActivatedRouteSnapshot) => {
    const organizationIdFromQueryParams = routeSnapshot.queryParamMap.get("organizationId");
    const authorizationService = inject(CodmanUserAuthorizationService);
    const authorizationServiceApi = inject(DataAccessAuthorizationApiService);
    const router = inject(Router);

    return combineLatest([
        authorizationService.getOrganizationsByRegistryId(null),
        authorizationService.organizationId$.pipe(startWith(NO_ORGANIZATION)),
        authorizationService.currentOrganizationPermissions$.pipe(startWith([])),
    ]).pipe(
        filter(([organizations, organizationId]) => {
            let organization = organizations.find(o => o.cicCode === organizationId);
            if (organization != null) {
                return true;
            }

            organization = organizations.find(
                o => o.organizationId === Number(organizationIdFromQueryParams),
            );

            // if no intial organization or it is not found in valic cic codes
            // use default and do not continue the pipe
            authorizationService.setDefaultOrganization(
                organizations,
                organization && organization.cicCode
                    ? organization.cicCode?.toString()
                    : organizationIdFromQueryParams,
            );
            return false;
        }),
        switchMap(() => authorizationServiceApi.getProfileExistence()),
        defaultIfEmpty(false),
        map(hasAccess => {
            if (!hasAccess) {
                return router.parseUrl("access-denied");
            }
            return hasAccess;
        }),
    );
};
