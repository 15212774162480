import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { auth0GuardCanActivate, auth0GuardCanLoad } from "@codman/shared/util-authorization";
import { AccessDeniedComponent, UnsubscribedComponent } from "@codman/shared/feature-static-pages";
import { portalGuard } from "./portal-guard";

const routes: Routes = [
    {
        path: "",
        pathMatch: "full",
        redirectTo: "portal",
    },
    {
        path: "access-denied",
        component: AccessDeniedComponent,
    },
    {
        path: "portal",
        loadChildren: () => import("@codman/portal/dashboard").then(m => m.PortalDashboardModule),
        canLoad: [auth0GuardCanLoad],
        canActivate: [auth0GuardCanActivate, portalGuard],
    },
    {
        path: "unsubscribed",
        component: UnsubscribedComponent,
    },
    {
        path: "**",
        redirectTo: "portal",
        pathMatch: "full",
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
